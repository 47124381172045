<template>
	<div class="main">
		<Navbar />

		<UserGroups class="groups" embed="false" public-groups="true" :form="true" />
	</div>
</template>


<script>
	import Navbar from "@/components/Navbar";
	import UserGroups from "@/components/side/UserGroups";

	export default {
		name: "Groups",
		components: {Navbar, UserGroups}
	}
</script>


<style lang="scss" scoped>
	.main {
		.groups {
			width: 40%;
			margin: 20px auto;

			@include respond-to('medium') {
				width: 70%;
			}
			@include respond-to('small') {
				width: 90%;
			}
		}
	}
</style>
